import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';

function LanguageCard({lang, title, img, bgcolor}) {
  const url = "/landing?lang=" + lang;
  return (
    <Link style={{ paddingRight:20 }} to={url}>
    <Card style={{ height:400, color: 'white', background:bgcolor, width: '200px', borderRadius:'25px' }}>
      <Card.Title>
      </Card.Title>
      <Card.Body>
        <Card.Text style={{ padding:10}}>
      <ListGroup variant="flush">
        <ListGroup.Item> <h2>{lang}</h2> </ListGroup.Item>
        <ListGroup.Item>{title}</ListGroup.Item>
      </ListGroup>
        </Card.Text>
      </Card.Body>
      <Card.Img variant="top" width="200px" height="250px" border-radius="25px" src={img} />
    </Card>
    </Link>
  );
}

export default LanguageCard;
