import React, { useState } from 'react';
import './searchstyles.css';

const SearchActor = (props) => {
  // Declare a state variable for the search input
  const [searchQuery, setSearchQuery] = useState(props.text);
  const firstPage = 0;

  // Handle input change
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle button click
  const handleSearch = () => {
    alert(`Searching for: ${searchQuery}`);
  };

  return (
    <div style={{overflowX:'scroll', whiteSpace:'nowrap'}} className="search-buttons">
      <input
        type="text"
        placeholder="Search Any Actor"
        value={searchQuery}
        onChange={handleInputChange}
      />
      <button onClick={() => props.onClick(searchQuery, firstPage)} >
        Search
      </button>
      <button onClick={ () => { setSearchQuery(''); props.onClearActorSearch() } } >
        Clear Search
      </button>
    </div>
  );
};

export default SearchActor;
