import React from 'react';
import './ActorPagination.css'; // Import your custom CSS file for styling
import GenreImageComponent from './GenreImageComponent.js';

function GenrePagination({ selectedGenre, genres, onGenreChange }) {

  if (selectedGenre == null) {
    if (genres != null) {
      if (genres[0] != null) {
        selectedGenre = genres[0];
      }
    }
  }

  return (
    <div className="actor-pagination">
      {genres.map((genre) => (
        <GenreImageComponent
          key={genre.movieGenreId}
          data_item={genre} isClicked={genre.movieGenreName === selectedGenre.movieGenreName ? true : false} selectedGenre={selectedGenre} onClick={onGenreChange} />
      ))}
    </div>
  );
}

export default GenrePagination;
