import React from 'react';

const Privacy = (props) => {
  return (
      <center>
      <h1>
         **{props.name}**
      </h1>
      <br/>
      <div style={{textAlign:'justify', paddingLeft:'200px', paddingRight:'200px', display:'flex', flexWrap:'wrap'}}>

      <p>
At dhoomdb.com your privacy is our top priority. This Privacy Policy explains how we handle your browsing information when you visit our website. Since we do not require you to log in, collect personal data, or use cookies, this policy will focus on how we protect your information while you browse our site.
      </p>

      <p>
**1. Information We Collect**  
We do not collect any personal or identifiable information when you visit our website. We do not require any form of registration, login, or submission of personal details. The only information exchanged during your visit is related to your browsing session, which includes:

- **Browsing Data**: Non-personal information, such as your IP address, browser type, device information, and geographical location (derived from your IP address), may be temporarily processed as part of our website's normal operation. This information is not stored or linked to your identity and is used only for basic operational purposes.
- **SSL Encryption**: All data exchanged between your device and our website is transmitted securely over SSL (Secure Sockets Layer) encryption. This ensures that your browsing data is protected from interception or unauthorized access while in transit.
      </p>

      <p>
**2. How We Protect Your Information**  
We are committed to maintaining the security of your information. Although we do not collect personal data, we take the following steps to protect the browsing data exchanged between your device and our website:

- **SSL Encryption**: All information exchanged between your device and our servers is encrypted using SSL technology. This means that any data transmitted, including your IP address and browsing activity, is securely encrypted to prevent unauthorized access.
- **No Data Storage**: We do not store any personal or identifiable information collected during your visit. Any temporary data (such as IP addresses or session data) is only used for the duration of your session and is not retained after your visit ends.
      </p>

      <p>
**3. Cookies and Tracking Technologies**  
Our website does not use cookies or other tracking technologies (such as pixels, beacons, or scripts) to collect or store any information about your browsing activities. Since we do not use cookies, you will not be tracked across sessions or sites.
      </p>

      <p>
**4. Third-Party Services**  
Our website does not engage in sharing your browsing data with third-party advertisers or analytics services. We do not use third-party services that track or collect personal information from visitors.

However, please note that some external services (such as embedded videos or social media links) on our website may have their own privacy policies. We are not responsible for the privacy practices or content of these external services. We encourage you to review the privacy policies of any third-party sites you visit.
      </p>

      <p>
**5. Your Rights**  
Since we do not collect or store any personal data, there are no specific rights related to your data that you need to exercise. However, you can always control the security of your browsing session by ensuring your connection to our site is secure and that you are using an up-to-date browser.
      </p>

      <p>
**6. Changes to This Privacy Policy**  
We reserve the right to update this Privacy Policy from time to time. If any changes are made, we will post the updated policy on this page and update the "Effective Date" at the top of the page. We encourage you to periodically review this page to stay informed about how we are protecting your privacy.
      </p>

      <p>
**7. Contact Us**  
If you have any questions or concerns regarding this Privacy Policy or how we handle your information, please feel free to contact us at email: contact.dhoomdb@gmail.com

By using our website, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.
      </p>

      <p>
No more juggling multiple subscriptions or searching for hours to find the perfect content. With dhoomdb.com you can effortlessly stream the best of both worlds, whenever and wherever you want. Join us today and discover the future of entertainment – made simple, made for you.
      Thank you for checking us out! This website is still in the process of improvement. Keep checking us, we will be updating our website frequently.  </p>

      </div>

<br/>
<br/>
<br/>
      </center>

  );
}

export default Privacy;

