// NavigationBar.js

import React from 'react';
import NavLabel from './NavLabel';

function NavigationBar() {
  return (
    <nav style={styles.nav}>
      <ul style={styles.navList}>
	{/* <NavLabel nav_style={styles} image="/images/cineFlix.png" lable="Movies"/> */}
	<NavLabel pageimg="/" image="images/dhoomdb.png" lable="dhoomdb"/>
	<NavLabel page="landing?lang=Hindi" lable="Hindi"/>
	<NavLabel page="landing?lang=Telugu" lable="Telugu"/>
	<NavLabel page="landing?lang=Tamil" lable="Tamil"/>
	{/*AF <NavLabel page="landing?lang=Marathi" lable="Marathi"/>
	<NavLabel page="landing?lang=Bengali" lable="Bengali"/>
	<NavLabel page="landing?lang=English" lable="English"/>
	<NavLabel page="landing?lang=Kannada	" lable="Kannada"/>
	<NavLabel page="landing?lang=Malayalam" lable="Malayalam"/>
	<NavLabel page="landing?lang=Punjabi" lable="Punjabi"/>
	<NavLabel page="landing?lang=Bhojpuri" lable="Bhojpuri"/>
	<NavLabel page="landing?lang=Gujarati" lable="Gujarati"/>
	<NavLabel page="landing?lang=Rajasthani" lable="Rajasthani"/> */}
      </ul>
    </nav>
  );
}

const styles = {
  nav: {
    width: '100%',
    height: 60,
    backgroundColor: 'black',
    padding: '0px',
    overflowX: 'scroll',
    //position: 'fixed',
  },
  navList: {
    listStyle: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: '0',
    marginBlock: 'auto',
  },
}

export default NavigationBar;

