import React from 'react';
import './ActorPagination.css'; // Import your custom CSS file for styling
import DirectorImageComponent from './DirectorImageComponent.js';

function DirectorPagination({ selectedDirector, directors, onDirectorChange }) {

  if (selectedDirector == null) {
    if (directors != null) {
      if (directors[0] != null) {
        selectedDirector = directors[0];
      }
    }
  }

  return (
    <div className="actor-pagination">
      {directors.map((director) => (
        <DirectorImageComponent
          key={director.directorId}
          data_item={director} isClicked={director.directorName === selectedDirector.directorName ? true : false} selectedDirector={selectedDirector} onClick={onDirectorChange} />
      ))}
    </div>
  );
}

export default DirectorPagination;
