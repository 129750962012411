// Tabs.js
import React, { useState } from 'react';
import './tabstyles.css';
import Movies from './Movies';

const Tabs = ({ lang, tabs, defaultTabKey }) => {
  const [activeTab, setActiveTab] = useState(defaultTabKey);

//  const lang='Hindi';

  const changeTab = (tabKey) => {
    console.log("Tab is =" + tabKey);
    setActiveTab(tabKey);
  };

  return (
    <div>
      <div style={{overflowX:'scroll', whiteSpace:'nowrap'}} className="tab-buttons">
        {tabs.map((tab) => (
          <button
            key={tab.key}
            onClick={() => changeTab(tab.key)}
            className={tab.key === activeTab ? 'active' : ''}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div>
		<Movies lang={lang} category={activeTab} pageNumber="1"/>
      </div>
    </div>
  );
};

export default Tabs;
